const ThemeNordr = () => {
    return (
        <>
            <style global jsx>{`
            @import url('https://use.typekit.net/tnx5zee.css');

            @keyframes fadeOut {
                0% {
                    opacity: 1;
                }
                100% {
                    opacity: 0;
                }
            }

            @keyframes fadeIn {
                0% {
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                }
            }

            @keyframes fadeInHero {
                0% {
                    opacity: 0;
                }
                100% {
                    opacity: .95;
                }
            }

            @keyframes slideUpFadeIn {
                0% {
                    opacity: 0;
                    transform: translate(0px, 40px);
                }
                100% {
                    opacity: 1;
                    z-index: 1;
                    transform: translate(0px, 0px);
                }
            }

            @keyframes slideDownFadeIn {
                0% {
                    opacity: 0;
                    transform: translate(0px, -40px);
                }
                100% {
                    opacity: 1;
                    transform: translate(0px, 0px);
                }
            }

            :root {
                --fontFamilyBase: 'semplicitapro', Arial, sans-serif;
                --fontBaseColor: #333333;
                --backgroundColor: transparent;
                // --colorBrand:
                // --colorBrandDark:
                --colorBlack: #000000;
                --colorWhite: #ffffff;
                --colorBlueLight100: #5f959d;
                --colorGradientGreen100: #18576f;
                --colorBlueDark100: #250f40;
                --colorGray100: #262626;
                --colorBrownLight20RGB: 244,243,242;
            }

            body {
                overflow-x: hidden;
            }

            body::selection {
                color: var(--colorWhite);
                background: var(--colorBlueLight100);
            }

            h1 {
                line-height: 1.45;
                font-weight: 500;
                letter-spacing: 1.2px;
                font-size: 32px;
            }
            // @media (min-width: var(--breakpointMd)) {
            @media (min-width: 832px) {
                h1 {
                    font-size: 5.0rem;
                }
            }

            h2 {
                line-height: 1.45;
                font-weight: 500;
                margin-bottom: 18px;
                letter-spacing: 0.8px;
                font-size: 24px;
            }
            // @media (min-width: var(--breakpointMd)) {
            @media (min-width: 832px) {
                h2 {
                    font-size: 3.2rem;
                }
            }


            h3 {
                line-height: 1.45;
                font-weight: 500;
                margin-bottom: 12px;
                letter-spacing: 0.8px;
                font-size: 1.8rem;
            }
            // @media (min-width: var(--breakpointMd)) {
            @media (min-width: 832px) {
                h3 {
                    font-size: 2.2rem;
                }
            }

            h4 {
                line-height: 1.45;
                font-weight: 500;
                margin-bottom: 15px;
                letter-spacing: 0.3px;
                font-size: 1.4rem;
            }

            h5 {
                line-height: 1.45;
                font-weight: 500;
                margin-bottom: 10px;
            }

            h6 {
                line-height: 1.45;
                font-weight: 500;
                margin-bottom: 10px;
            }

            p {
                font-size: 1.6rem;
                line-height: 28px;
            }
            @media print {
                p {
                    font-size: 1.4rem;
                    line-height: 1.5;
                }
            }

            a {
                color: var(--colorGradientGreen100);
            }

            b {
                font-weight: 500;
            }

            .Wysiwyg a:hover {
                color: rgba(var(--colorGradientGreen100), 0.7);
            }

            .Wysiwyg h1 {
                line-height: 1.45;
                font-weight: 500;
                letter-spacing: 1.2px;
                font-size: 32px;
            }
            @media (min-width: 832px) {
                .Wysiwyg h1 {
                    font-size: 5rem;
                }
            }

            .Wysiwyg h2 {
                line-height: 1.45;
                font-weight: 500;
                margin-bottom: 18px;
                letter-spacing: 0.8px;
                font-size: 2.4rem;
            }
            @media (min-width: 832px) {
                .Wysiwyg h2 {
                    font-size: 3.2rem;
                }
            }

            .Wysiwyg h3 {
                line-height: 1.45;
                font-weight: 500;
                margin-bottom: 12px;
                letter-spacing: 0.8px;
                font-size: 1.8rem;
            }
            @media (min-width: 832px) {
                .Wysiwyg h3 {
                    font-size: 2.2rem;
                }
            }
            .Wysiwyg h4 {
                line-height: 1.45;
                font-weight: 500;
                margin-bottom: 15px;
                letter-spacing: 0.3px;
                font-size: 1.6rem;
            }

            .Wysiwyg h5 {
                line-height: 1.45;
                font-weight: 500;
                margin-bottom: 10px;
            }

            .Wysiwyg h6 {
                line-height: 1.45;
                font-weight: 500;
                margin-bottom: 10px;
            }

            .Wysiwyg p {
                color: var(--colorBlack);
                font-weight: 400;
                margin-bottom: 24px;
            }
            .Wysiwyg p:last-child {
                margin-bottom: 0;
            }

            .Wysiwyg ul {
                color: var(--colorBlack);
                max-width: 550px;
                margin-bottom: 64px;
            }
            @media (min-width: 832px) {
                .Wysiwyg ul {
                    margin-bottom: 80px;
                }
            }

            .Wysiwyg ul li {
                margin-bottom: 15px;
                line-height: 28px;
                font-weight: 400;
                position: relative;
                padding-left: 20px;
            }
            .Wysiwyg ul li:before {
                left: 0;
                content: '';
                display: block;
                min-width: 6px;
                height: 6px;
                border-radius: 50%;
                width: 6px;
                position: absolute;
                margin-top: 12px;
                margin-right: 15px;
                background-color: var(--colorBlueDark100);
            }

            .Wysiwyg ol {
                color: var(--colorBlack);
                max-width: 550px;
                counter-reset: olCounter;
                margin-bottom: 64px;
            }
            @media (min-width: 832px) {
                .Wysiwyg ol {
                    margin-bottom: 80px;
                }
            }
            .Wysiwyg ol li {
                display: flex;
                margin-bottom: 15px;
                line-height: 28px;
                font-weight: 400;
            }
            .Wysiwyg ol li:before {
                content: counter(olCounter) '.';
                display: block;
                min-width: 16px;
                font-weight: 400;
                margin-right: 5px;
                color: var(--colorBlueDark100);
                font-size: 1.6rem;
                counter-increment: olCounter;
            }

            `}</style>
            <RcSliderTheme />
        </>
    );
}

const RcSliderTheme = () => (
    <style global jsx>{`
    .rc-slider {
        position: relative;
        box-sizing: border-box;
        width: calc(100% - 24px);
        height: 24px;
        margin-left: 12px;
        padding: 12px 0;
        border-radius: 6px;

        -ms-touch-action: none;
        touch-action: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    .rc-slider * {
        box-sizing: border-box;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    .rc-slider-rail {
        position: absolute;
        right: -12px;
        left: -12px;
        height: 2px;
        border-radius: 6px;
        background-color: #c0bbb8;
    }

    .rc-slider-track {
        position: absolute;
        left: 0;
        height: 2px;
        border-radius: 6px;
        background-color: #5f959d;
    }

    .rc-slider-handle {
        position: absolute;
        width: 24px;
        height: 24px;
        margin-top: -11px;
        margin-left: -12px;
        border: solid 6px #bfd5d8;
        border-radius: 50%;
        background-color: #5f959d;
        cursor: pointer;
        cursor: -webkit-grab;
        cursor: grab;

        -ms-touch-action: pan-x;
        touch-action: pan-x;
    }

    .rc-slider-handle:hover {
        //border-color: $colorSecondaryLight;
    }

    .rc-slider-handle:active {
        //border-color: $colorSecondaryLight;
        cursor: -webkit-grabbing;
        cursor: grabbing;
    }

    .rc-slider-handle:focus {
        //border-color: $colorSecondaryLight;
        outline: none;
    }

    .rc-slider-mark {
        position: absolute;
        top: 18px;
        left: 0;
        width: 100%;
        font-size: 12px;
    }

    .rc-slider-mark-text {
        position: absolute;
        display: inline-block;
        color: #999;
        vertical-align: middle;
        text-align: center;
        cursor: pointer;
    }

    .rc-slider-mark-text-active {
        color: #666;
    }

    .rc-slider-step {
        position: absolute;
        width: 100%;
        height: 2px;
        background: transparent;
    }

    .rc-slider-dot {
        position: absolute;
        bottom: -2px;
        width: 8px;
        height: 8px;
        margin-left: -4px;
        vertical-align: middle;
        border: 2px solid #dfdcda;
        border-radius: 50%;
        background-color: #fff;
        cursor: pointer;
    }

    .rc-slider-dot-active {
        /* border-color: #96dbfa; */
    }

    .rc-slider-disabled {
        background-color: #dfdcda;
    }

    .rc-slider-disabled .rc-slider-track {
        background-color: #ccc;
    }

    .rc-slider-disabled .rc-slider-handle,
    .rc-slider-disabled .rc-slider-dot {
        border-color: #ccc;
        background-color: #fff;
        cursor: not-allowed;
    }

    .rc-slider-disabled .rc-slider-mark-text,
    .rc-slider-disabled .rc-slider-dot {
        cursor: not-allowed !important;
    }

    .rc-slider-vertical {
        width: 14px;
        height: 100%;
        padding: 0 5px;
    }

    .rc-slider-vertical .rc-slider-rail {
        width: 2px;
        height: 100%;
    }

    .rc-slider-vertical .rc-slider-track {
        bottom: 0;
        left: 5px;
        width: 2px;
    }

    .rc-slider-vertical .rc-slider-handle {
        margin-bottom: -7px;
        margin-left: -5px;

        -ms-touch-action: pan-y;
        touch-action: pan-y;
    }

    .rc-slider-vertical .rc-slider-mark {
        top: 0;
        left: 18px;
        height: 100%;
    }

    .rc-slider-vertical .rc-slider-step {
        width: 2px;
        height: 100%;
    }

    .rc-slider-vertical .rc-slider-dot {
        left: 2px;
        margin-bottom: -4px;
    }

    .rc-slider-vertical .rc-slider-dot:first-child {
        margin-bottom: -4px;
    }

    .rc-slider-vertical .rc-slider-dot:last-child {
        margin-bottom: -4px;
    }

    .rc-slider-tooltip-zoom-down-enter,
    .rc-slider-tooltip-zoom-down-appear {
        display: block !important;
        -webkit-animation-duration: 0.3s;
        animation-duration: 0.3s;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;

        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }

    .rc-slider-tooltip-zoom-down-leave {
        display: block !important;
        -webkit-animation-duration: 0.3s;
        animation-duration: 0.3s;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;

        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }

    .rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
    .rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
        -webkit-animation-name: rcSliderTooltipZoomDownIn;
        animation-name: rcSliderTooltipZoomDownIn;
        -webkit-animation-play-state: running;
        animation-play-state: running;
    }

    .rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
        -webkit-animation-name: rcSliderTooltipZoomDownOut;
        animation-name: rcSliderTooltipZoomDownOut;
        -webkit-animation-play-state: running;
        animation-play-state: running;
    }

    .rc-slider-tooltip-zoom-down-enter,
    .rc-slider-tooltip-zoom-down-appear {
        -webkit-transform: scale(0, 0);
        transform: scale(0, 0);
        -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
        animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    }

    .rc-slider-tooltip-zoom-down-leave {
        -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    }

    @-webkit-keyframes rcSliderTooltipZoomDownIn {
        0% {
            opacity: 0;
            -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
        }
        100% {
            -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
        }
    }

    @keyframes rcSliderTooltipZoomDownIn {
        0% {
            opacity: 0;
            -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
        }
        100% {
            -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
        }
    }

    @-webkit-keyframes rcSliderTooltipZoomDownOut {
        0% {
            -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
        }
        100% {
            opacity: 0;
            -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
        }
    }

    @keyframes rcSliderTooltipZoomDownOut {
        0% {
            -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
        }
        100% {
            opacity: 0;
            -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
        }
    }

    .rc-slider-tooltip {
        position: absolute;
        top: -9999px;
        left: -9999px;
        visibility: visible;
        box-sizing: border-box;

        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    .rc-slider-tooltip * {
        box-sizing: border-box;

        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    .rc-slider-tooltip-hidden {
        display: none;
    }

    .rc-slider-tooltip-placement-top {
        padding: 4px 0 8px 0;
    }

    .rc-slider-tooltip-inner {
        min-width: 24px;
        height: 24px;
        padding: 6px 2px;
        color: #fff;
        font-size: 12px;
        line-height: 1;
        text-align: center;
        text-decoration: none;
        border-radius: 6px;
        background-color: #6c6c6c;
    }

    .rc-slider-tooltip-arrow {
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-color: transparent;
    }

    .rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
        bottom: 4px;
        left: 50%;
        margin-left: -4px;
        border-width: 4px 4px 0;
        border-top-color: #6c6c6c;
    }
    `}</style>
)

export default ThemeNordr;
